<template>
  <div v-if="financialInformation">
    <div v-if="isAdministrator && financialInformation.merchantId"
      class="row"
    >
      <p class="col-12">
          <span class="u-fw-semi-bold">{{
              SHARED.FinancialInformation.MerchantId.Label
          }}</span>
          <span :class="`qa-provider-legal-company-merchantId-${financialInformationIndex} ml-2`">{{ financialInformation.merchantId }}</span>
      </p>
    </div>
    <div class="row">
      <recess-input
        :disabled="isAccountManager"
        v-model="financialInformation.name"
        :label-text="`${SHARED.FinancialInformation.Name.Label}*`"
        :error-message="
          legalCompanyNameError(financialInformationIndex) ||
          legalCompanyUniqueNameError(financialInformationIndex)
        "
        :class="`qa-provider-legal-company-name-${financialInformationIndex} col-6`"
        @blur="$v.financialInformation.name.$touch()"
      />
      <recess-input
        :disabled="isAccountManager"
        v-model="financialInformation.vatNumber"
        :label-text="`${SHARED.FinancialInformation.VatNumber.Label}`"
        :class="`qa-provider-legal-company-vat-number-${financialInformationIndex} col-6`"
      />
    </div>
    <div class="row">
        <recess-input
          :disabled="isAccountManager"
          v-model="financialInformation.kvk"
          :label-text="`${SHARED.FinancialInformation.Kvk.Label}*`"
          :error-message="kvkError"
          :class="`qa-provider-legal-company-kvk-${financialInformationIndex} col-2`"
          @blur="$v.financialInformation.kvk.$touch()"
        />
        <recess-input
          :disabled="isAccountManager"
          v-model="financialInformation.iban"
          :label-text="`${SHARED.FinancialInformation.Iban.Label}*`"
          :error-message="ibanError()"
          :class="`qa-provider-legal-company-iban-${financialInformationIndex} col-4`"
          @blur="$v.financialInformation.iban.$touch()"
        />
        <recess-input
          :disabled="isAccountManager"
          v-model="financialInformation.bicSwift"
          :label-text="`${SHARED.FinancialInformation.BicSwift.Label}*`"
          :error-message="bicSwiftError()"
          :class="`qa-provider-legal-company-bicSwift-${financialInformationIndex} col-3`"
          @blur="$v.financialInformation.bicSwift.$touch()"
        />
        
        <div class="col-3">
          <recess-input
            v-if="legalCompanyMode === 'Provider'"
            v-model="financialInformation.creditNumber"
            :label-text="SHARED.FinancialInformation.CreditNumber.Label"
            :class="`qa-provider-legal-company-creditNumber-${financialInformationIndex}`"
            :disabled="!isAdministrator || isAccountManager"
          />
  
          <recess-input
            v-if="legalCompanyMode === 'Account'"
            v-model="financialInformation.debtorNumber"
            :label-text="SHARED.FinancialInformation.DebtorNumber.Label"
            :class="`qa-provider-legal-company-debtorNumber-${financialInformationIndex}`"
            :disabled="!isAdministrator || isAccountManager"
          />
        </div>
    </div>
    <div class="row">
        <recess-input
          v-model="financialInformation.emailAddress"
          :label-text="`${SHARED.FinancialInformation.EmailAddress.Label}*`"
          :error-message="
            isEmailRequired() ||
            emailAddressError() ||
            emailAddressUniqueError()
          "
          :class="`qa-provider-legal-company-email-address-${financialInformationIndex} col-6`"
          @blur="$v.financialInformation.emailAddress.$touch()"
          :disabled="isAccountManager"
        />
    </div>
  </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import UserService from "../../../shared/services/UserService";
import { isRequiredErrorMessage, hasEmailError, uniqueError } from "../../constants/validationMessageHelper.js";

export default {
  name: "FinancialInformationForm",
  mixins: [validationMixin],
  validations: {
    financialInformation: {
      name: {
        required,
        isUniqueName(value, item) {
          if (this.financialExistingNames) {
            const legalCompaniesNames = this.financialExistingNames.filter(
              (legalCompanyName) => {
                return legalCompanyName.name === item.name;
              }
            );

            return legalCompaniesNames.length < 2;
          }

          return true;
        },
      },
      kvk: {
        required
      },
      iban: {
        required,
      },
      bicSwift: {
        required,
      },
      emailAddress: {
        required,
        email,
        isUniqueEmailAddress(value, item) {
          if (this.financialExistingNames) {
            const legalCompaniesEmailAddresses =
              this.financialExistingNames.filter((legalCompanyEmailAddress) => {
                return (
                  legalCompanyEmailAddress.emailAddress !== null &&
                  legalCompanyEmailAddress.emailAddress !== undefined &&
                  legalCompanyEmailAddress.emailAddress === item.emailAddress
                );
              });

            return legalCompaniesEmailAddresses.length < 2;
          }

          return true;
        },
      },
    },
  },
  props: {
    isAccountManager: {
      type: Boolean,
      default() {
        return false
      }
    },
    financialExistingNames: {
      type: Array,
      default() {
        return [];
      },
    },
    financialInformation: {
      type: Object,
      default: () => {},
    },
    financialInformationIndex: {
      type: Number,
      default: 0,
    },
    legalCompanyMode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      userService: new UserService(),
      isAdministrator: false,
      SHARED
    };
  },
  async created() {
    this.isAdministrator = await this.userService.isAdministrator();
  },
  methods: {
    isUserLoggedIn(user) {
      if (isLoggedIn()) {
        return user;
      }
      return false;
    },
    legalCompanyNameError() {
      return isRequiredErrorMessage("name", "Naam", this.$v.financialInformation.name);
    },
    legalCompanyUniqueNameError() {
      return this.$v.financialInformation.name.$error &&
        !this.$v.financialInformation.name.isUniqueName
        ? uniqueError('Naam')
        : null;
    },
    isEmailRequired() {
      return isRequiredErrorMessage(
        "emailAddress",
        "E-mailadres",
        this.$v.financialInformation.emailAddress
      );
    },
    ibanError() {
      return isRequiredErrorMessage("iban", "IBAN", this.$v.financialInformation.iban);
    },
    bicSwiftError() {
      return isRequiredErrorMessage(
        "bicSwift",
        "BIC/Swift nr",
        this.$v.financialInformation.bicSwift
      );
    },
    emailAddressError() {
      if (!this.$v.financialInformation.emailAddress.$error) return null;

      return hasEmailError(this.$v.financialInformation.emailAddress)
    },
    emailAddressUniqueError() {
      return this.$v.financialInformation.emailAddress.$error &&
        !this.$v.financialInformation.emailAddress.isUniqueEmailAddress ?
        uniqueError('E-mailadres van een handelsnaam')
        : null;
    },
  },
  computed: {
    kvkError() {
     return isRequiredErrorMessage("kvk", "Kvk", this.$v.financialInformation.kvk);
    }
  }
}
</script>